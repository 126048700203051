body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a,
a.ms-Link {
  color: #0078d4;
  text-decoration: underline;
}

.ms-Button a {
  text-decoration: none;
}

.ms-MessageBar a.ms-Link {
  color: #0078d4;
}

.announcements a {
  color: white;
  text-decoration: underline;
}

a:not(header a):not(a.ms-Button):not(.home-resource-item a, .home-footer-item
    a, footer a, .announcements a):not(.api-datasource-item a):hover {
  text-decoration: underline !important;
  color: #0078d4;
}

.page-header .breadcrumbs a,
.page-header .breadcrumbs a:hover {
  color: #fff !important;
}

form a {
  text-decoration: underline !important;
}

p {
  line-height: 1.6;
}

li p {
  margin: 6px 0;
}

/* The documentation site adds a header for buttons
   on external notebooks that launch the file in the Hub.
   This is done via sphinx config and is not injecting React
   components. Instead, style the buttons to mimic the fluentui
   buttons used elsewhere in the project.
*/
.docs-launcher {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 10px;
}

.manual-ms-button {
  outline: transparent;
  position: relative;
  font-size: 14px;
  font-weight: 600;
  box-sizing: border-box;
  border: 1px solid;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  margin-right: 10px;
}

.manual-ms-button.manual-ms-primary {
  border-color: rgb(0, 120, 212);
  background-color: rgb(0, 120, 212);
  color: rgb(255, 255, 255);
}

.docs-launcher a.manual-ms-button.manual-ms-primary:hover {
  background-color: rgb(16, 110, 190);
  border: 1px solid rgb(16, 110, 190);
  text-decoration: none;
  color: rgb(255, 255, 255);
}

.manual-ms-button.manual-ms-secondary {
  border-color: rgb(138, 136, 134);
  background-color: rgb(255, 255, 255);
  color: rgb(50, 49, 48);
}

.docs-launcher a.manual-ms-button.manual-ms-secondary:hover {
  background-color: rgb(243, 242, 241);
  color: rgb(32, 31, 30);
  text-decoration: none;
}

.manual-ms-button .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.manual-ms-button .flex-container span {
  line-height: 100%;
  display: block;
  margin: 0px 4px;
}

.responsive-container-wide {
  width: 100%;
  padding-bottom: 56.25%; /* 9/16 = aspect ratio of image */
  position: relative;
}

.responsive-container-wide img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

main.short {
  min-height: calc(100vh - 100px);
}

.page-header {
  background-color: #f9f9f9;
  background-size: cover;
}

.page-header .grid-content {
  padding: 80px 0;
}

/* Light hover effect for link images */
main a img {
  transition: 0.5s ease;
  border-radius: 2px;
}

.ms-Modal img {
  transition: 0.5s ease;
}

.ms-Modal .catalog-collection-item:hover img {
  opacity: 0.85;
}

a:not(header a):hover img {
  opacity: 0.85;
}

.page-header h1 {
  max-width: 50%;
  margin-top: 0px;
}

/* Generalized grid layouts */

@media screen and (min-width: 800px) {
  .toc-item {
    width: 200px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 30px;
  }
}

@media (max-width: 768px) {
  form button {
    width: 100%;
  }

  .nav-internal {
    display: none;
  }
}

.nav-internal {
  min-width: 175px;
  margin-left: 30px;
  height: fit-content;
  padding: 20px 0 25px 0;
}
.home-resources.layout-container {
  margin: 100px 0;
}

.home-resources .home-resource-item a {
  text-decoration: none;
}

.home-resource-item:hover .item-header {
  text-decoration: underline;
}

.home-resources .layout-row {
  justify-content: center;
}

.caption-text {
  font-weight: bold;
}

.toc-item {
  margin-right: 18px;
  padding-top: 15px;
  padding-right: 10px;
  max-height: calc(100vh - 50px);
  overflow: auto;
}

.toctree-wrapper ul {
  list-style-type: none;
  margin: 0 5px;
  padding: 0;
}

.toctree-wrapper li {
  padding: 2px 0;
  margin: 4px 0;
}

.toctree-wrapper li.indent {
  padding-left: 8px;
}

.toctree-wrapper p {
  margin: 10px 0 2px 0;
}

.toctree-wrapper .caption {
  font-weight: bold;
  margin-bottom: 2px;
}

.toctree-wrapper a {
  text-decoration: none;
}

.generated-docs {
  width: 75%;
  margin-bottom: 40px;
}

/* Documentation markup from generated sources */
.generated-docs img {
  object-fit: contain;
  max-height: 100%;
  max-width: 100%;
}
/* Corporate footer styling from UHF */

/* All gridded content conforms to these margins */
@media (min-width: 1080px) {
  .grid-content {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .off-grid-content {
    padding-right: 5% !important;
  }
  footer .off-grid-content {
    padding-left: 8px;
  }
}

/* Smaller screens ~ tablet size get very small margins */
@media (max-width: 1079px) {
  .grid-content {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .off-grid-content {
    padding-right: 20px !important;
  }
  footer .off-grid-content {
    padding-left: 5px;
  }

  .docs-toc-nav {
    display: none;
  }

  .overflow-docs-nav {
    display: block;
  }

  .generated-docs {
    width: 100%;
  }
}

.c-uhff-base {
  background: #f2f2f2;
  margin: 0 auto;
  padding-top: 5px;
  padding-bottom: 5px;
}

.c-uhff-base ul.c-list {
  font-size: 11px;
  line-height: 16px;
  color: #616161;
}

.c-list.f-bare {
  padding-left: 0;
  list-style-type: none;
}

.c-uhff-base ul.c-list li {
  padding: 0 24px 4px 0;
  display: inline-block;
}
footer.context-uhf a:link,
footer.context-uhf a:focus,
footer.context-uhf a:hover,
footer.context-uhf a:active,
footer.context-uhf a:visited {
  text-decoration: none;
  color: inherit;
}

.with-sidebar {
  overflow: visible;
}

.with-sidebar > * {
  display: flex;
  flex-wrap: wrap;
  margin: calc(40px / 2 * -1);
}

.with-sidebar > * > * {
  margin: calc(40px / 2);
  flex-grow: 1;
}

/* Collection sidebar 

https://every-layout.dev/layouts/sidebar/

*/
.with-sidebar > * > :first-child {
  flex-basis: 0;
  flex-grow: 999;
  min-width: calc(45% - 1rem);
}

.collection-content .collection-content-item {
  padding-bottom: 15px;
}

.main-content {
  padding-bottom: 80px;
}

/* Convert collection detail <ul> to comma seperated list */

ul.summary-list {
  margin: 0;
}

.collection-content-item ul:not(.summary-list) {
  display: inline;
  list-style: none;
  padding-left: 0;
}

.collection-content-item ul:not(.summary-list) li {
  display: inline;
}

.collection-content-item ul:not(.summary-list) li:after {
  content: ", ";
}

.collection-content-item li:last-child:after {
  content: "";
}

.collection-content-item a {
  text-decoration: underline;
}

/* Swagger UI overrides */
.swagger-ui .info h2.title {
  font-size: 25px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: rgb(50, 49, 48);
  margin-top: 15px;
}

.swagger-ui h4.opblock-tag {
  font-size: 20px;
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system,
    BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
  color: rgb(50, 49, 48);
}

.swagger-ui .info {
  margin: 0 !important;
}

.swagger-ui .info .main .title small {
  background-color: #333;
}

.swagger-ui .info .main .title small.version-stamp {
  background-color: #008000;
}

.swagger-ui .info a {
  color: #0078d4 !important;
}

.opblock-summary-get span.opblock-summary-method {
  background-color: #0078d4 !important;
}

.opblock-summary-post span.opblock-summary-method {
  background-color: #008000 !important;
}

.ms-Modal-scrollableContent::-webkit-scrollbar,
.custom-overflow::-webkit-scrollbar,
.ms-Callout-main::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.ms-Modal-scrollableContent::-webkit-scrollbar-thumb,
.custom-overflow::-webkit-scrollbar-thumb,
.ms-Callout-main::-webkit-scrollbar-thumb {
  border-radius: 8px;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.ms-Modal-scrollableContent::-webkit-scrollbar-track,
.custom-overflow::-webkit-scrollbar-track,
.ms-Callout-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  background-color: #fff;
}

.hood {
  margin: -11px;
  overflow: hidden;
  height: 13px;
  z-index: 1;
}

.hood:after {
  content: "";
  display: block;
  width: 100%;
  height: 30px;
  margin: -30px auto 0;
  border-radius: 100px / 20px;
}

.hood.on:after {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
}

/* Code blocks inside of anchors needs darker text color for color contrast */
a code {
  color: #006cbe;
}
