.markdown-source {
  min-width: 70%;
}

.markdown-source code,
.generated-docs code {
  background-color: #1b1f230d;
  padding: 0.2em 0.4em;
  border-radius: 6px;
}

.generated-docs blockquote {
  padding: 0 1.5em;
  margin-left: 0;
  border-left: 0.3em solid #e0e0e0;
}

.markdown-source li {
  margin-top: 0.4em;
}

.input_area,
.section .highlight-python,
.section .highlight-json,
.section .highlight-bash,
.generated-docs .highlight-bash,
.generated-docs .highlight-json,
.generated-docs .highlight-python {
  border: 1px solid #e0e0e0;
  background: #f9f9f9;
  padding: 0px 10px;
  border-radius: 3px;
  margin-top: 15px;
}

.generated-docs video {
  width: 100%;
}

.generated-docs p {
  overflow-wrap: break-word;
}

.input_area .highlight,
.generated-docs .highlight {
  margin-top: 1em;
}

.output_area {
  margin-top: 15px;
}

.output_area .highlight pre {
  margin: 1em;
}

.output_area.rendered_html.docutils.container {
  width: 100%;
  overflow: auto;
}

.highlight pre {
  margin-top: 0em;
}

a.anchor-link,
a.headerlink {
  display: none;
}

.prompt.input_prompt {
  color: #616161;
  padding-bottom: 4px;
}
.nbinput .prompt {
  display: none;
}

.output_png img,
.output_svg svg {
  max-width: 100%;
}

/* Turn off In/Out prompts */
div.nbinput div.prompt,
div.nboutput div.prompt,
div.prompt.input_prompt,
div.prompt.output_prompt {
  display: none;
}

.admonition-title {
  margin: 0;
  color: #fff;
  font-weight: 600;
  padding: 6px;
}

.admonition.note .admonition-title {
  background: #267ac2;
}

.admonition-title {
  border-radius: 3px 3px 0 0;
}

.admonition.warning .admonition-title {
  background: #fed9cc;
  color: #000;
}

.admonition.tip .admonition-title {
  background: rgba(255, 193, 7, 0.25);
  color: #000;
}

.admonition p:not(.admonition-title) {
  margin: 0;
  border: 1px solid #e0e0e0;
  border-top: 0;
  padding: 14px 6px;
  border-radius: 0 0 3px 3px;
}

/* Use darker text for accessible contrast for xarray output table columns */
.xr-var-item .xr-preview,
.xr-var-item .xr-var-dtype {
  color: rgba(0, 0, 0, 0.6);
}

pre {
  line-height: 125%;
  white-space: pre-wrap;
  overflow: auto;
}
td.linenos .normal {
  color: inherit;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
}
span.linenos {
  color: inherit;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
}
td.linenos .special {
  color: #000000;
  background-color: #ffffc0;
  padding-left: 5px;
  padding-right: 5px;
}
span.linenos.special {
  color: #000000;
  background-color: #ffffc0;
  padding-left: 5px;
  padding-right: 5px;
}
.highlight .hll {
  background-color: var(--jp-cell-editor-active-background);
}
.highlight .c {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment */
.highlight .err {
  color: var(--jp-mirror-editor-error-color);
} /* Error */
.highlight .k {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword */
.highlight .o {
  color: var(--jp-mirror-editor-operator-color);
  font-weight: bold;
} /* Operator */
.highlight .p {
  color: var(--jp-mirror-editor-punctuation-color);
} /* Punctuation */
.highlight .ch {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment.Hashbang */
.highlight .cm {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment.Multiline */
.highlight .cp {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment.Preproc */
.highlight .cpf {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment.PreprocFile */
.highlight .c1 {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment.Single */
.highlight .cs {
  color: var(--jp-mirror-editor-comment-color);
  font-style: italic;
} /* Comment.Special */
.highlight .kc {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword.Constant */
.highlight .kd {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword.Declaration */
.highlight .kn {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword.Namespace */
.highlight .kp {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword.Pseudo */
.highlight .kr {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword.Reserved */
.highlight .kt {
  color: var(--jp-mirror-editor-keyword-color);
  font-weight: bold;
} /* Keyword.Type */
.highlight .m {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number */
.highlight .s {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String */
.highlight .ow {
  color: var(--jp-mirror-editor-operator-color);
  font-weight: bold;
} /* Operator.Word */
.highlight .w {
  color: var(--jp-mirror-editor-variable-color);
} /* Text.Whitespace */
.highlight .mb {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number.Bin */
.highlight .mf {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number.Float */
.highlight .mh {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number.Hex */
.highlight .mi {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number.Integer */
.highlight .mo {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number.Oct */
.highlight .sa {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Affix */
.highlight .sb {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Backtick */
.highlight .sc {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Char */
.highlight .dl {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Delimiter */
.highlight .sd {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Doc */
.highlight .s2 {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Double */
.highlight .se {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Escape */
.highlight .sh {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Heredoc */
.highlight .si {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Interpol */
.highlight .sx {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Other */
.highlight .sr {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Regex */
.highlight .s1 {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Single */
.highlight .ss {
  color: var(--jp-mirror-editor-string-color);
} /* Literal.String.Symbol */
.highlight .il {
  color: var(--jp-mirror-editor-number-color);
} /* Literal.Number.Integer.Long */

:root {
  /* Elevation
   *
   * We style box-shadows using Material Design's idea of elevation. These particular numbers are taken from here:
   *
   * https://github.com/material-components/material-components-web
   * https://material-components-web.appspot.com/elevation.html
   */

  --jp-shadow-base-lightness: 0;
  --jp-shadow-umbra-color: rgba(
    var(--jp-shadow-base-lightness),
    var(--jp-shadow-base-lightness),
    var(--jp-shadow-base-lightness),
    0.2
  );
  --jp-shadow-penumbra-color: rgba(
    var(--jp-shadow-base-lightness),
    var(--jp-shadow-base-lightness),
    var(--jp-shadow-base-lightness),
    0.14
  );
  --jp-shadow-ambient-color: rgba(
    var(--jp-shadow-base-lightness),
    var(--jp-shadow-base-lightness),
    var(--jp-shadow-base-lightness),
    0.12
  );
  --jp-elevation-z0: none;
  --jp-elevation-z1: 0px 2px 1px -1px var(--jp-shadow-umbra-color),
    0px 1px 1px 0px var(--jp-shadow-penumbra-color),
    0px 1px 3px 0px var(--jp-shadow-ambient-color);
  --jp-elevation-z2: 0px 3px 1px -2px var(--jp-shadow-umbra-color),
    0px 2px 2px 0px var(--jp-shadow-penumbra-color),
    0px 1px 5px 0px var(--jp-shadow-ambient-color);
  --jp-elevation-z4: 0px 2px 4px -1px var(--jp-shadow-umbra-color),
    0px 4px 5px 0px var(--jp-shadow-penumbra-color),
    0px 1px 10px 0px var(--jp-shadow-ambient-color);
  --jp-elevation-z6: 0px 3px 5px -1px var(--jp-shadow-umbra-color),
    0px 6px 10px 0px var(--jp-shadow-penumbra-color),
    0px 1px 18px 0px var(--jp-shadow-ambient-color);
  --jp-elevation-z8: 0px 5px 5px -3px var(--jp-shadow-umbra-color),
    0px 8px 10px 1px var(--jp-shadow-penumbra-color),
    0px 3px 14px 2px var(--jp-shadow-ambient-color);
  --jp-elevation-z12: 0px 7px 8px -4px var(--jp-shadow-umbra-color),
    0px 12px 17px 2px var(--jp-shadow-penumbra-color),
    0px 5px 22px 4px var(--jp-shadow-ambient-color);
  --jp-elevation-z16: 0px 8px 10px -5px var(--jp-shadow-umbra-color),
    0px 16px 24px 2px var(--jp-shadow-penumbra-color),
    0px 6px 30px 5px var(--jp-shadow-ambient-color);
  --jp-elevation-z20: 0px 10px 13px -6px var(--jp-shadow-umbra-color),
    0px 20px 31px 3px var(--jp-shadow-penumbra-color),
    0px 8px 38px 7px var(--jp-shadow-ambient-color);
  --jp-elevation-z24: 0px 11px 15px -7px var(--jp-shadow-umbra-color),
    0px 24px 38px 3px var(--jp-shadow-penumbra-color),
    0px 9px 46px 8px var(--jp-shadow-ambient-color);

  /* Borders
   *
   * The following variables, specify the visual styling of borders in JupyterLab.
   */

  --jp-border-width: 1px;
  --jp-border-color0: var(--md-grey-400);
  --jp-border-color1: var(--md-grey-400);
  --jp-border-color2: var(--md-grey-300);
  --jp-border-color3: var(--md-grey-200);
  --jp-border-radius: 2px;

  /* UI Fonts
   *
   * The UI font CSS variables are used for the typography all of the JupyterLab
   * user interface elements that are not directly user generated content.
   *
   * The font sizing here is done assuming that the body font size of --jp-ui-font-size1
   * is applied to a parent element. When children elements, such as headings, are sized
   * in em all things will be computed relative to that body size.
   */

  --jp-ui-font-scale-factor: 1.2;
  --jp-ui-font-size0: 0.83333em;
  --jp-ui-font-size1: 13px; /* Base font size */
  --jp-ui-font-size2: 1.2em;
  --jp-ui-font-size3: 1.44em;

  --jp-ui-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  /*
   * Use these font colors against the corresponding main layout colors.
   * In a light theme, these go from dark to light.
   */

  /* Defaults use Material Design specification */
  --jp-ui-font-color0: rgba(0, 0, 0, 1);
  --jp-ui-font-color1: rgba(0, 0, 0, 0.87);
  --jp-ui-font-color2: rgba(0, 0, 0, 0.54);
  --jp-ui-font-color3: rgba(0, 0, 0, 0.38);

  /*
   * Use these against the brand/accent/warn/error colors.
   * These will typically go from light to darker, in both a dark and light theme.
   */

  --jp-ui-inverse-font-color0: rgba(255, 255, 255, 1);
  --jp-ui-inverse-font-color1: rgba(255, 255, 255, 1);
  --jp-ui-inverse-font-color2: rgba(255, 255, 255, 0.7);
  --jp-ui-inverse-font-color3: rgba(255, 255, 255, 0.5);

  /* Content Fonts
   *
   * Content font variables are used for typography of user generated content.
   *
   * The font sizing here is done assuming that the body font size of --jp-content-font-size1
   * is applied to a parent element. When children elements, such as headings, are sized
   * in em all things will be computed relative to that body size.
   */

  --jp-content-line-height: 1.6;
  --jp-content-font-scale-factor: 1.2;
  --jp-content-font-size0: 0.83333em;
  --jp-content-font-size1: 14px; /* Base font size */
  --jp-content-font-size2: 1.2em;
  --jp-content-font-size3: 1.44em;
  --jp-content-font-size4: 1.728em;
  --jp-content-font-size5: 2.0736em;

  /* This gives a magnification of about 125% in presentation mode over normal. */
  --jp-content-presentation-font-size1: 17px;

  --jp-content-heading-line-height: 1;
  --jp-content-heading-margin-top: 1.2em;
  --jp-content-heading-margin-bottom: 0.8em;
  --jp-content-heading-font-weight: 500;

  /* Defaults use Material Design specification */
  --jp-content-font-color0: rgba(0, 0, 0, 1);
  --jp-content-font-color1: rgba(0, 0, 0, 0.87);
  --jp-content-font-color2: rgba(0, 0, 0, 0.54);
  --jp-content-font-color3: rgba(0, 0, 0, 0.38);

  --jp-content-link-color: var(--md-blue-700);

  --jp-content-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  /*
   * Code Fonts
   *
   * Code font variables are used for typography of code and other monospaces content.
   */

  --jp-code-font-size: 13px;
  --jp-code-line-height: 1.3077; /* 17px for 13px base */
  --jp-code-padding: 5px; /* 5px for 13px base, codemirror highlighting needs integer px value */
  --jp-code-font-family-default: Menlo, Consolas, "DejaVu Sans Mono", monospace;
  --jp-code-font-family: var(--jp-code-font-family-default);

  /* This gives a magnification of about 125% in presentation mode over normal. */
  --jp-code-presentation-font-size: 16px;

  /* may need to tweak cursor width if you change font size */
  --jp-code-cursor-width0: 1.4px;
  --jp-code-cursor-width1: 2px;
  --jp-code-cursor-width2: 4px;

  /* Layout
   *
   * The following are the main layout colors use in JupyterLab. In a light
   * theme these would go from light to dark.
   */

  --jp-layout-color0: white;
  --jp-layout-color1: white;
  --jp-layout-color2: var(--md-grey-200);
  --jp-layout-color3: var(--md-grey-400);
  --jp-layout-color4: var(--md-grey-600);

  /* Inverse Layout
   *
   * The following are the inverse layout colors use in JupyterLab. In a light
   * theme these would go from dark to light.
   */

  --jp-inverse-layout-color0: #111111;
  --jp-inverse-layout-color1: var(--md-grey-900);
  --jp-inverse-layout-color2: var(--md-grey-800);
  --jp-inverse-layout-color3: var(--md-grey-700);
  --jp-inverse-layout-color4: var(--md-grey-600);

  /* Brand/accent */

  --jp-brand-color0: var(--md-blue-700);
  --jp-brand-color1: var(--md-blue-500);
  --jp-brand-color2: var(--md-blue-300);
  --jp-brand-color3: var(--md-blue-100);
  --jp-brand-color4: var(--md-blue-50);

  --jp-accent-color0: var(--md-green-700);
  --jp-accent-color1: var(--md-green-500);
  --jp-accent-color2: var(--md-green-300);
  --jp-accent-color3: var(--md-green-100);

  /* State colors (warn, error, success, info) */

  --jp-warn-color0: var(--md-orange-700);
  --jp-warn-color1: var(--md-orange-500);
  --jp-warn-color2: var(--md-orange-300);
  --jp-warn-color3: var(--md-orange-100);

  --jp-error-color0: var(--md-red-700);
  --jp-error-color1: var(--md-red-500);
  --jp-error-color2: var(--md-red-300);
  --jp-error-color3: var(--md-red-100);

  --jp-success-color0: var(--md-green-700);
  --jp-success-color1: var(--md-green-500);
  --jp-success-color2: var(--md-green-300);
  --jp-success-color3: var(--md-green-100);

  --jp-info-color0: var(--md-cyan-700);
  --jp-info-color1: var(--md-cyan-500);
  --jp-info-color2: var(--md-cyan-300);
  --jp-info-color3: var(--md-cyan-100);

  /* Cell specific styles */

  --jp-cell-padding: 5px;

  --jp-cell-collapser-width: 8px;
  --jp-cell-collapser-min-height: 20px;
  --jp-cell-collapser-not-active-hover-opacity: 0.6;

  --jp-cell-editor-background: var(--md-grey-100);
  --jp-cell-editor-border-color: var(--md-grey-300);
  --jp-cell-editor-box-shadow: inset 0 0 2px var(--md-blue-300);
  --jp-cell-editor-active-background: var(--jp-layout-color0);
  --jp-cell-editor-active-border-color: var(--jp-brand-color1);

  --jp-cell-prompt-width: 64px;
  --jp-cell-prompt-font-family: "Source Code Pro", monospace;
  --jp-cell-prompt-letter-spacing: 0px;
  --jp-cell-prompt-opacity: 1;
  --jp-cell-prompt-not-active-opacity: 0.5;
  --jp-cell-prompt-not-active-font-color: var(--md-grey-700);
  /* A custom blend of MD grey and blue 600
   * See https://meyerweb.com/eric/tools/color-blend/#546E7A:1E88E5:5:hex */
  --jp-cell-inprompt-font-color: #307fc1;
  /* A custom blend of MD grey and orange 600
   * https://meyerweb.com/eric/tools/color-blend/#546E7A:F4511E:5:hex */
  --jp-cell-outprompt-font-color: #bf5b3d;

  /* Notebook specific styles */

  --jp-notebook-padding: 10px;
  --jp-notebook-select-background: var(--jp-layout-color1);
  --jp-notebook-multiselected-color: var(--md-blue-50);

  /* The scroll padding is calculated to fill enough space at the bottom of the
  notebook to show one single-line cell (with appropriate padding) at the top
  when the notebook is scrolled all the way to the bottom. We also subtract one
  pixel so that no scrollbar appears if we have just one single-line cell in the
  notebook. This padding is to enable a 'scroll past end' feature in a notebook.
  */
  --jp-notebook-scroll-padding: calc(
    100% - var(--jp-code-font-size) * var(--jp-code-line-height) -
      var(--jp-code-padding) - var(--jp-cell-padding) - 1px
  );

  /* Rendermime styles */

  --jp-rendermime-error-background: #fdd;
  --jp-rendermime-table-row-background: var(--md-grey-100);
  --jp-rendermime-table-row-hover-background: var(--md-light-blue-50);

  /* Dialog specific styles */

  --jp-dialog-background: rgba(0, 0, 0, 0.25);

  /* Console specific styles */

  --jp-console-padding: 10px;

  /* Toolbar specific styles */

  --jp-toolbar-border-color: var(--jp-border-color1);
  --jp-toolbar-micro-height: 8px;
  --jp-toolbar-background: var(--jp-layout-color1);
  --jp-toolbar-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.24);
  --jp-toolbar-header-margin: 4px 4px 0px 4px;
  --jp-toolbar-active-background: var(--md-grey-300);

  /* Input field styles */

  --jp-input-box-shadow: inset 0 0 2px var(--md-blue-300);
  --jp-input-active-background: var(--jp-layout-color1);
  --jp-input-hover-background: var(--jp-layout-color1);
  --jp-input-background: var(--md-grey-100);
  --jp-input-border-color: var(--jp-border-color1);
  --jp-input-active-border-color: var(--jp-brand-color1);
  --jp-input-active-box-shadow-color: rgba(19, 124, 189, 0.3);

  /* General editor styles */

  --jp-editor-selected-background: #d9d9d9;
  --jp-editor-selected-focused-background: #d7d4f0;
  --jp-editor-cursor-color: var(--jp-ui-font-color0);

  /* Code mirror specific styles */

  --jp-mirror-editor-keyword-color: #008000;
  --jp-mirror-editor-atom-color: #88f;
  --jp-mirror-editor-number-color: rgb(7, 112, 7);
  --jp-mirror-editor-def-color: #00f;
  --jp-mirror-editor-variable-color: var(--md-grey-900);
  --jp-mirror-editor-variable-2-color: #05a;
  --jp-mirror-editor-variable-3-color: #085;
  --jp-mirror-editor-punctuation-color: #05a;
  --jp-mirror-editor-property-color: #05a;
  --jp-mirror-editor-operator-color: #aa22ff;
  --jp-mirror-editor-comment-color: #276767;
  --jp-mirror-editor-string-color: #ba2121;
  --jp-mirror-editor-string-2-color: #708;
  --jp-mirror-editor-meta-color: #901dd8;
  --jp-mirror-editor-qualifier-color: #555;
  --jp-mirror-editor-builtin-color: #008000;
  --jp-mirror-editor-bracket-color: #997;
  --jp-mirror-editor-tag-color: #170;
  --jp-mirror-editor-attribute-color: #00c;
  --jp-mirror-editor-header-color: blue;
  --jp-mirror-editor-quote-color: #090;
  --jp-mirror-editor-link-color: #00c;
  --jp-mirror-editor-error-color: #f00;
  --jp-mirror-editor-hr-color: #999;

  /* Vega extension styles */

  --jp-vega-background: white;

  /* Sidebar-related styles */

  --jp-sidebar-min-width: 180px;

  /* Search-related styles */

  --jp-search-toggle-off-opacity: 0.5;
  --jp-search-toggle-hover-opacity: 0.8;
  --jp-search-toggle-on-opacity: 1;
  --jp-search-selected-match-background-color: rgb(245, 200, 0);
  --jp-search-selected-match-color: black;
  --jp-search-unselected-match-background-color: var(--jp-inverse-layout-color0);
  --jp-search-unselected-match-color: var(--jp-ui-inverse-font-color0);

  /* Icon colors that work well with light or dark backgrounds */
  --jp-icon-contrast-color0: var(--md-purple-600);
  --jp-icon-contrast-color1: var(--md-green-600);
  --jp-icon-contrast-color2: var(--md-pink-600);
  --jp-icon-contrast-color3: var(--md-blue-600);
}
