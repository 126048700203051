.layout-container {
  margin: 0 auto;
}
.layout-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.header-banner-item {
  flex-basis: 48.5%;
}

/* Collection table header resize element override */
.ms-DetailsHeader-cell:hover + .ms-DetailsHeader-cellSizer::after {
  opacity: 1;
  background: darkgray;
}

.json-list span {
  font-size: inherit;
}

.json-list .collection-content-item:not(:first-child) {
  margin-top: 5px;
}

/* Catalog datasets */

.layout-row-primary {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  column-gap: 40px;
  row-gap: 20px;
  margin-top: 40px;
}

.layout-row-other {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
}

@media (max-width: 766px) {
  .layout-row-primary {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }
  .layout-row-other {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    row-gap: 20px;
  }
}

@media (max-width: 555px) {
  .layout-row-primary {
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
  .layout-row-other {
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 20px;
  }
}

.api-datasource-item {
  flex-basis: 48.5%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 32px;
}

@media (max-width: 1333px) {
  .api-datasource-item {
    flex-basis: 48.5%;
  }
}
@media (max-width: 815px) {
  .api-datasource-item {
    flex-basis: 100%;
    padding-right: 0;
  }
}

.keywords-bar button:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.15);
}

/* Homepage resource grid */

.home-resource-item {
  flex-basis: 24%;
  width: 259px;
  position: relative;
  padding-right: 20px;
  box-sizing: border-box;
}

@media (max-width: 1073px) {
  .home-resource-item {
    flex-basis: 48.5%;
    padding-bottom: 40px;
  }

  .home-resource-item:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 555px) {
  .home-resource-item {
    flex-basis: 100%;
    padding-right: 0;
  }
}

/* Header banner */
.header-banner {
  background: #f9f9f9;
  margin-bottom: 40px;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 30px;
}

@media (max-width: 766px) {
  .header-banner {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
}

.banner-item {
  max-width: 800px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.banner-item h1 {
  margin-top: 0;
}

.banner-item p {
  font-weight: 500;
  margin: 0;
}

@media (max-width: 555px) {
  .banner-item {
    text-align: center;
    flex-basis: 100%;
    justify-content: center;
  }
}

/* Home banner footer */

.home-footer-container {
  background: #0078d4;
}

.home-footer-grid {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}

.home-footer-item {
  flex-basis: 48.5%;
  width: 259px;
  position: relative;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 40px;
  box-sizing: border-box;
}
.home-footer-item:first-child {
  padding-right: 165px;
}

@media (max-width: 555px) {
  .home-footer-item {
    flex-basis: 100%;
  }

  .home-strip-container {
    display: none !important;
  }
}

@media (max-width: 1285px) {
  .home-footer-item:first-child {
    padding-right: 20px;
  }
}

/* Home image strip */

.home-strip-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}

@media (max-width: 766px) {
  .home-strip-item {
    width: 50%;
  }

  img.home-strip-item:nth-child(-n + 4) {
    display: none;
  }
}

@media (min-width: 766px) {
  .home-strip-item {
    width: 25%;
    object-fit: contain;
    height: 100%;
  }
}

@media (max-width: 700px) {
  .hero {
    text-align: center;
    height: 400px !important;
  }

  .hero .title {
    font-size: 40px;
    text-shadow: 1px 1px black;
  }
}

/* docs and example table style */
.markdown-source table {
  margin-right: auto;
  border: none;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.markdown-source table td {
  padding: 5px 10px;
  vertical-align: top;
  border-bottom: 1px solid #e0e0e0;
}

th.head {
  text-align: left;
  padding-left: 10px;
}

/* xarray dataframe table output */
.markdown-source table.dataframe {
  font-size: 12px;
}

.markdown-source thead {
  border-bottom: 1px solid #e0e0e0;
  vertical-align: bottom;
}
.markdown-source .dataframe tr,
.markdown-source .dataframe th,
.markdown-source .dataframe td {
  text-align: right;
  vertical-align: middle;
  padding: 0.5em 0.5em;
  line-height: normal;
  white-space: normal;
  max-width: none;
  border: none;
}
.markdown-source .dataframe th {
  font-weight: bold;
}
.markdown-source .dataframe tbody tr:nth-child(odd) {
  background: #f5f5f5;
}
.markdown-source .dataframe tbody tr:hover {
  background: rgba(66, 165, 245, 0.2);
}
* + table {
  margin-top: 1em;
}
